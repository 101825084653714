import React, { ReactElement, useCallback } from 'react';
import SimpleButtonDropdown from '@rio-cloud/rio-uikit/SimpleButtonDropdown';
import type { MenuItemProps } from '@rio-cloud/rio-uikit/MenuItem';
import { FormattedMessage } from 'react-intl';
import { useLazyDownloadMaintenanceHistoryQuery } from '../api/maintenanceHistoryApi';
import { useSelector } from 'react-redux';
import { getLocale } from '../../../configuration/lang/langSlice';
import { DEFAULT_LOCALE } from '../../../configuration/lang/lang';

type ExportOptionConfig = {
    format: string;
    icon: string;
    mimeType: string;
};

export const toPdfOption: ExportOptionConfig = {
    icon: 'rioglyph-pdf-file',
    format: 'pdf',
    mimeType: 'application/pdf',
};

export const toExcelOption: ExportOptionConfig = {
    icon: 'rioglyph-files',
    format: 'excel',
    mimeType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
};

export interface ExportDropdownButtonProps {
    assetId: string;
    options: ExportOptionConfig[];
    header?: string;
}

const ExportDropdownButton = ({ assetId, options, header }: ExportDropdownButtonProps): ReactElement => {
    const [triggerDownload, { isFetching }] = useLazyDownloadMaintenanceHistoryQuery();
    const locale = useSelector(getLocale) ?? DEFAULT_LOCALE;
    const itemsMapper = useCallback(
        ({ format, icon, mimeType }: ExportOptionConfig): MenuItemProps => {
            return {
                value: <ExportButton format={format} icon={icon} />,
                onSelect: () =>
                    triggerDownload({
                        assetId,
                        params: {
                            locale,
                        },
                        headers: {
                            Accept: mimeType,
                        },
                    }),
            };
        },
        [assetId, locale],
    );
    const items: MenuItemProps[] = [];
    if (header) {
        items.push({
            value: <FormattedMessage id={header} />,
            header: true,
        });
    }
    items.push(...options.map(itemsMapper));
    return (
        <SimpleButtonDropdown
            items={items}
            disabled={isFetching}
            iconOnly
            title={<span className="rioglyph rioglyph-download" />}
            dropdownClassName="download-menu"
        />
    );
};

type ExportButtonProps = {
    format: string;
    icon: string;
};

const ExportButton = ({ format, icon }: ExportButtonProps) => (
    <span className="display-flex gap-5 align-items-center">
        <span className={`rioglyph ${icon}`} />
        <FormattedMessage id={`intl-msg:maintenanceHistory.exportDropdownMenu.${format}`} />
    </span>
);

export default ExportDropdownButton;
