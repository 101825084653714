import { FormattedMessage, FormattedNumber } from 'react-intl';
import { Criticality } from '../../../generated/GeneratedApiTypes';
import NoData from '../NoData';

const getPanelStatus = (status: Criticality) =>
    ({
        DANGER: 'status-critical',
        OK: 'status-ok',
        UNDEFINED: '',
    })[status] || '';

const getSingleStatusPanelContent = (
    value: number,
    unit: string,
    percentageTimeAbove: number | undefined,
    tooltip: React.ReactElement<any, string | React.JSXElementConstructor<any>> | undefined,
) => (
    <div>
        <FormattedNumber value={value} minimumFractionDigits={0} maximumFractionDigits={2} />
        {unit}
        {percentageTimeAbove !== undefined && percentageTimeAbove > 1 && (
            <span className="label label-default label-condensed text-uppercase text-color-danger border-color-danger margin-left-5">
                <span className="margin-right-5">
                    <FormattedMessage id="intl-msg:maintenanceHistory.lifetime.totalPercentageTimeAbove" />
                </span>
                <FormattedNumber
                    value={percentageTimeAbove / 100}
                    minimumFractionDigits={1}
                    maximumFractionDigits={2}
                    style={'percent'}
                />
            </span>
        )}
        {tooltip}
    </div>
);

export interface SingleStatusPanelProps {
    dataTestId: string;
    status: Criticality;
    value: number;
    percentageTimeAbove?: number;
    unit: string;
    titleI18n: string;
    tooltip?: React.ReactElement;
    panelExtraClasses?: string;
}

const SingleStatusPanel = ({
    dataTestId,
    status,
    value,
    percentageTimeAbove,
    unit,
    titleI18n,
    tooltip,
    panelExtraClasses,
}: SingleStatusPanelProps) => (
    <div className={`col-xs-12 col-sm-6 col-md-6 display-flex ${panelExtraClasses}`}>
        <div
            className={`panel panel-default margin-bottom-0 width-100pct ${getPanelStatus(status)}`}
            data-testid={dataTestId}
        >
            <div className="panel-body">
                <div className={'display-flex'}>
                    <span className={'text-size-14'}>
                        <FormattedMessage id={titleI18n} />
                    </span>
                </div>
                {value !== undefined ? (
                    getSingleStatusPanelContent(value, unit, percentageTimeAbove, tooltip)
                ) : (
                    <NoData />
                )}
            </div>
        </div>
    </div>
);

export default SingleStatusPanel;
