import MaintenanceHistoryTable from './MaintenanceHistoryTable';
import LifetimeStatus from './lifetime_status/LifetimeStatus';
import { useState } from 'react';
import InternalErrorState from '../app/InternalErrorState';

const ErrorState = () => (
    <InternalErrorState
        headlineI18n={'intl-msg:maintenanceHistory.error.headline'}
        messageI18n={'intl-msg:maintenanceHistory.error.message'}
    />
);

const MaintenancePage = () => {
    const [showError, setShowError] = useState<boolean>(false);

    if (showError) {
        return <ErrorState />;
    }

    return (
        <>
            <LifetimeStatus setShowError={setShowError} />
            <MaintenanceHistoryTable />
        </>
    );
};

export default MaintenancePage;
