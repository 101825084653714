import { useMatch } from 'react-router';
import { MaintenanceHistoryParameters, useFetchMaintenanceHistoryQuery } from './api/maintenanceHistoryApi';
import { MaintenanceHistoryList, MaintenanceHistoryRow } from '../../generated/GeneratedApiTypes';
import { FormattedDate, FormattedMessage, FormattedNumber } from 'react-intl';
import React, { useState } from 'react';
import { LoadingRow } from '../app/LoadingRow';
import InternalErrorState from '../app/InternalErrorState';
import CustomDateFilter from './CustomDateFilter';
import SimpleTooltip from '@rio-cloud/rio-uikit/SimpleTooltip';
import NoData from './NoData';

const translateComponent = (cId: number): React.ReactElement => {
    switch (cId) {
        case 1:
            return <FormattedMessage id="intl-msg:maintenanceHistory.component.engineOil" />;
        default:
            return <FormattedMessage id="intl-msg:maintenanceHistory.component.invalidComponent" />;
    }
};

const isDangerValue = (value: number | undefined): boolean => {
    return value !== undefined && value > 0;
};

const getClassNameBasedOnValue = (value: number | undefined): string => {
    return isDangerValue(value) ? 'text-color-danger' : 'text-color-black';
};

const getClassNameRow = (row: MaintenanceHistoryRow): string => {
    if (isDangerValue(row.overdue_in_pct) || isDangerValue(row.mileage_overdue) || isDangerValue(row.days_overdue)) {
        return 'danger';
    } else {
        return 'default';
    }
};

const getCellValueBasedOnValue = (
    value: number | undefined,
    isForDaysOverdue?: boolean,
): string | React.ReactElement => {
    if (value === undefined) {
        return <NoData />;
    } else if (value > 0) {
        if (isForDaysOverdue) {
            return <FormattedMessage id="intl-msg:maintenanceHistory.component.daysOverdue" values={{ value }} />;
        } else {
            return (
                <FormattedNumber
                    value={value / 100}
                    minimumFractionDigits={2}
                    maximumFractionDigits={2}
                    style={'percent'}
                />
            );
        }
    } else {
        return <FormattedMessage id="intl-msg:maintenanceHistory.component.notOverdue" />;
    }
};

const LITRES = ' l';
const KILOMETERS = ' km';

const Title = () => (
    <div className="space-y-50">
        <p className={'text-size-h4'}>
            <FormattedMessage id="intl-msg:maintenanceHistory.title" />
        </p>
    </div>
);

const renderServiceProduct = (
    serviceProduct:
        | {
              value: string;
              max_fuel_consumption_limit: number;
              max_mileage_limit: number;
              max_time_limit: number;
          }
        | undefined,
) => {
    if (serviceProduct === undefined) {
        return <NoData />;
    }
    return (
        <>
            <span>{serviceProduct.value}</span>
            <SimpleTooltip
                content={
                    <>
                        <p className={'margin-top-0'}>
                            <FormattedMessage id={'intl-msg:maintenanceHistory.serviceProduct.tooltip.header'} />
                        </p>
                        <div>
                            <FormattedMessage
                                id={'intl-msg:maintenanceHistory.serviceProduct.maxFuelConsumption'}
                                values={{
                                    fuel: (
                                        <FormattedNumber
                                            value={serviceProduct.max_fuel_consumption_limit}
                                            minimumFractionDigits={0}
                                            maximumFractionDigits={0}
                                        />
                                    ),
                                }}
                            />
                        </div>

                        <div>
                            <FormattedMessage
                                id={'intl-msg:maintenanceHistory.serviceProduct.maxMileage'}
                                values={{
                                    mileage: (
                                        <FormattedNumber
                                            value={serviceProduct.max_mileage_limit}
                                            minimumFractionDigits={0}
                                            maximumFractionDigits={0}
                                        />
                                    ),
                                }}
                            />
                        </div>

                        <div>
                            <FormattedMessage
                                id={'intl-msg:maintenanceHistory.serviceProduct.maxStandingTime'}
                                values={{
                                    time: (
                                        <FormattedNumber
                                            value={serviceProduct.max_time_limit}
                                            minimumFractionDigits={0}
                                            maximumFractionDigits={0}
                                        />
                                    ),
                                }}
                            />
                        </div>
                    </>
                }
                textAlignment={'left'}
            >
                <span
                    data-testid={'engine-oil-change-status-icon-tooltip'}
                    className="rioglyph rioglyph-info-sign text-size-16 margin-left-5"
                />
            </SimpleTooltip>
        </>
    );
};

const MaintenanceHistoryTable = () => {
    const [dateFrom, setDateFrom] = useState<string | undefined>(undefined);
    const [dateTo, setDateTo] = useState<string | undefined>(undefined);
    const assetId = useMatch('/:assetId')?.params.assetId;

    const params: MaintenanceHistoryParameters = {
        pathParameters: {
            assetId: assetId!!,
        },
        queryParameters: {
            from: dateFrom,
            to: dateTo,
        },
    };

    const handleDateFilterChange = (from: string | undefined, to: string | undefined) => {
        setDateFrom(from);
        setDateTo(to);
    };

    // variable to control whether we are ready to show the data
    const isReadyToFetch = assetId !== undefined && dateFrom !== undefined && dateTo !== undefined;

    const { isLoading, isError, data, isFetching } = useFetchMaintenanceHistoryQuery(params, {
        skip: !isReadyToFetch,
    });

    if (isError) {
        return (
            <>
                <Title />
                <InternalErrorState
                    headlineI18n={'intl-msg:maintenanceHistory.history.error.headline'}
                    messageI18n={'intl-msg:maintenanceHistory.history.error.message'}
                />
            </>
        );
    }

    const renderTableBody = () => {
        if (isLoading || isFetching || !isReadyToFetch) {
            return (
                <>
                    <LoadingRow numCells={8} />
                    <LoadingRow numCells={8} />
                </>
            );
        } else {
            return (data as MaintenanceHistoryList).map((row, index) => (
                <tr className={getClassNameRow(row)} key={index}>
                    <td>{translateComponent(row.component_id)}</td>
                    <td>
                        {row.date_of_current_service && (
                            <FormattedDate
                                value={row.date_of_current_service}
                                year="numeric"
                                month="numeric"
                                day="numeric"
                            />
                        )}
                    </td>
                    <td className={getClassNameBasedOnValue(row.overdue_in_pct)}>
                        {getCellValueBasedOnValue(row.overdue_in_pct)}
                    </td>
                    <td>
                        {
                            <FormattedNumber
                                value={row.fuel_consumption_since_previous_service}
                                minimumFractionDigits={0}
                                maximumFractionDigits={0}
                            />
                        }
                        {LITRES}
                    </td>
                    <td className={getClassNameBasedOnValue(row.mileage_overdue)}>
                        {getCellValueBasedOnValue(row.mileage_overdue)}
                    </td>
                    <td>
                        {
                            <FormattedNumber
                                value={row.mileage_since_previous_service}
                                minimumFractionDigits={0}
                                maximumFractionDigits={0}
                            />
                        }
                        {KILOMETERS}
                    </td>
                    <td className={getClassNameBasedOnValue(row.days_overdue)}>
                        {getCellValueBasedOnValue(row.days_overdue, true)}
                    </td>
                    <td>{renderServiceProduct(row.service_product)}</td>
                </tr>
            ));
        }
    };

    return (
        <>
            <Title />
            <div className={'margin-y-10 margin-bottom-15'}>
                <CustomDateFilter onDateChange={handleDateFilterChange} />
            </div>
            <div className={'table-responsive'}>
                <table className={'table table-head-filled table-bordered'}>
                    <thead>
                        <tr>
                            <th>
                                <FormattedMessage id={'intl-msg:maintenanceHistory.table.header.component'} />
                            </th>
                            <th>
                                <FormattedMessage id={'intl-msg:maintenanceHistory.table.header.serviceDate'} />
                            </th>
                            <th>
                                <FormattedMessage
                                    id={'intl-msg:maintenanceHistory.table.header.overdueInConsumption'}
                                />
                            </th>
                            <th>
                                <FormattedMessage
                                    id={'intl-msg:maintenanceHistory.table.header.fuelConsumptionSinceLastService'}
                                />
                            </th>
                            <th>
                                <FormattedMessage id={'intl-msg:maintenanceHistory.table.header.overdueInMileage'} />
                            </th>
                            <th>
                                <FormattedMessage
                                    id={'intl-msg:maintenanceHistory.table.header.mileageSinceLastService'}
                                />
                            </th>
                            <th>
                                <FormattedMessage id={'intl-msg:maintenanceHistory.table.header.overdueInTime'} />
                            </th>
                            <th>
                                <FormattedMessage id={'intl-msg:maintenanceHistory.table.header.serviceProduct'} />
                            </th>
                        </tr>
                    </thead>
                    <tbody className={'bg-white'}>{renderTableBody()}</tbody>
                </table>
            </div>
        </>
    );
};
export default MaintenanceHistoryTable;
