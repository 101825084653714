import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { prepareHeaders } from '../../../configuration/tokenHandling/prepareHeaders';
import { LifetimeStatus, MaintenanceHistoryList } from '../../../generated/GeneratedApiTypes';

export type MaintenanceHistoryParameters = {
    pathParameters: {
        assetId: string;
    };
    queryParameters: {
        from?: string;
        to?: string;
    };
};

type DownloadHistory = {
    assetId: string;
    params: Record<string, string>;
    headers: Record<string, string>;
};

export const maintenanceHistoryApi = createApi({
    reducerPath: 'maintenanceHistoryApi',
    baseQuery: fetchBaseQuery({ baseUrl: `${import.meta.env.VITE_BACKEND_URI}`, prepareHeaders }),
    endpoints: (builder) => ({
        fetchMaintenanceHistory: builder.query<MaintenanceHistoryList, MaintenanceHistoryParameters>({
            query: ({ pathParameters: { assetId }, queryParameters: { from, to } }) => {
                const queryParams = new URLSearchParams();

                if (from) queryParams.append('from', from);
                if (to) queryParams.append('to', to);

                return {
                    url: `/maintenance-history/${assetId}`,
                    params: queryParams,
                };
            },
        }),
        fetchLifeTimeStatus: builder.query<LifetimeStatus, String>({
            query: (assetId) => ({
                url: `/lifetime-status/${assetId}`,
            }),
        }),
        downloadMaintenanceHistory: builder.query<any, DownloadHistory>({
            query: ({ assetId, params, headers }) => {
                const defaultFilename = `${assetId}_${new Date().toISOString().split('.')[0]}.${params.format}`;
                const filenameRegexMatcher = /filename="(.+\.[a-z]{2,})"/gi;

                return {
                    url: `/download/${assetId}`,
                    params,
                    method: 'GET',
                    cache: 'no-cache',
                    headers,

                    responseHandler: async (response) => {
                        try {
                            if (!response.ok) {
                                return {
                                    isSuccess: false,
                                    reason: response.statusText,
                                };
                            }

                            const data = await response.blob();
                            const contentDisposition = response.headers.get('Content-Disposition') ?? 'NO_HEADER';
                            const filename = filenameRegexMatcher.exec(contentDisposition)?.[1] ?? defaultFilename;

                            const url = window.URL.createObjectURL(data);
                            const anchor = document.createElement('a');
                            anchor.href = url;
                            anchor.download = filename;
                            document.body.append(anchor);
                            anchor.click();
                            anchor.remove();
                            window.URL.revokeObjectURL(url);
                            return {
                                isSuccess: true,
                            };
                        } catch (e) {
                            return {
                                isSuccess: false,
                            };
                        }
                    },
                };
            },
        }),
    }),
});

export const { useFetchMaintenanceHistoryQuery, useFetchLifeTimeStatusQuery, useLazyDownloadMaintenanceHistoryQuery } =
    maintenanceHistoryApi;
