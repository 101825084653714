import DataTab from '@rio-cloud/rio-uikit/DataTab';
import DataTabs from '@rio-cloud/rio-uikit/DataTabs';
import ExpanderPanel from '@rio-cloud/rio-uikit/ExpanderPanel';
import ContentLoader from '@rio-cloud/rio-uikit/ContentLoader';
import { useState } from 'react';
import { Criticality, LifetimeStatus as LifetimeStatusType } from '../../../generated/GeneratedApiTypes';
import { useFetchLifeTimeStatusQuery } from '../api/maintenanceHistoryApi';
import { useMatch } from 'react-router';
import { FormattedDate, FormattedMessage } from 'react-intl';
import MaxEngineOilOverdueTooltip from './MaxEngineOilOverdueTooltip';
import Recommendation from './Recommendation';
import SingleStatusPanel from './SingleStatusPanel';
import DoubleStatusPanel from './DoubleStatusPanel';
import EngineOilChangeOverdueStatusPanel from './EngineOilChangeOverdueStatusPanel';
import PercentageTimeAboveTooltip from './PercentageTimeAboveTooltip';
import ExportDropdownButton, { toExcelOption, toPdfOption } from './ExportDropdownButton';

const TABS = {
    ENGINE: 'tab1',
} as const;

const TEMPERATURE_UNIT = ' °C';
const PERCENTAGE_UNIT = '%';
const RPM_UNIT = ' RPM';
const SECONDS_UNIT = ' s';
const COOLANT_TEMPERATURE_THRESHOLD = 105;
const ENGINE_OIL_TEMPERATURE_THRESHOLD = 135;

const StatusIcon = ({ status }: { status: Criticality }) => (
    <>
        <span
            className={`rioglyph rioglyph-${status === 'DANGER' ? 'error' : 'ok'}-sign text-size-16 margin-y-auto margin-right-5`}
        />
        <span>
            <FormattedMessage
                id={`intl-msg:maintenanceHistory.lifetime.vehicle${status === 'DANGER' ? 'Critical' : 'Ok'}`}
            />
        </span>
    </>
);

const expanderPanelTitle = (status: Criticality) => (
    <span className={status === 'DANGER' ? 'text-color-danger' : ''}>
        <StatusIcon status={status} />
    </span>
);

const DataTabTitle = () => (
    <>
        <span className="rioglyph rioglyph-engine text-size-16" />
        &nbsp;
        <FormattedMessage id="intl-msg:maintenanceHistory.component.engine" />
    </>
);

const LoadingState = () => (
    <ExpanderPanel open title={<DataTabTitle />} bsStyle="default">
        <ContentLoader height={80} />
    </ExpanderPanel>
);

export const engineOilChangeTooltip = (data: LifetimeStatusType) => {
    const { max_engine_oil_change_status } = data;
    const { is_current, origin, service_date } = max_engine_oil_change_status;

    return <MaxEngineOilOverdueTooltip serviceDate={service_date} isCurrent={is_current} origin={origin} />;
};

const engineOilTemperatureTooltip = (data: LifetimeStatusType) => {
    const percentageTimeAbove = data.max_engine_oil_status.percentage_total_time_above;
    return percentageTimeAbove > 1 ? (
        <PercentageTimeAboveTooltip threshold={ENGINE_OIL_TEMPERATURE_THRESHOLD} />
    ) : undefined;
};

const coolantTemperatureTooltip = (data: LifetimeStatusType) => {
    const percentageTimeAbove = data.max_coolant_status.percentage_total_time_above;
    return percentageTimeAbove > 1 ? (
        <PercentageTimeAboveTooltip threshold={COOLANT_TEMPERATURE_THRESHOLD} />
    ) : undefined;
};

interface LifetimeStatusProps {
    setShowError: (show: boolean) => void;
}

const LifetimeStatus = ({ setShowError }: LifetimeStatusProps) => {
    const assetId = useMatch('/:assetId')?.params.assetId!!;
    const [_, setTabKey] = useState('tab1');

    const { isLoading, isError, data } = useFetchLifeTimeStatusQuery(assetId);

    if (isLoading) return <LoadingState />;
    if (isError || (data !== undefined && data.overall_lifetime_status === 'UNDEFINED')) {
        setShowError(true);
        return <></>;
    }

    if (data !== undefined) {
        return (
            <>
                <div className={'display-grid gap-20 grid-cols-2-1fr-auto margin-bottom-15'}>
                    <div>
                        <span className={'rioglyph rioglyph-info-sign text-size-16 margin-y-auto'} />
                        <FormattedMessage
                            id="intl-msg:maintenanceHistory.lifetime.status.information"
                            values={{
                                date: (
                                    <FormattedDate
                                        value={data.timestamp}
                                        year="numeric"
                                        month="numeric"
                                        day="numeric"
                                    />
                                ),
                            }}
                        />
                    </div>
                    <div className="text-right margin-y-auto">
                        <ExportDropdownButton
                            assetId={assetId}
                            options={[toPdfOption, toExcelOption]}
                            header="intl-msg:maintenanceHistory.exportDropdownMenu.header"
                        />
                    </div>
                </div>

                <ExpanderPanel
                    open={data.overall_lifetime_status === 'DANGER'}
                    title={expanderPanelTitle(data.overall_lifetime_status)}
                    bsStyle="default"
                >
                    <DataTabs
                        activeKey={TABS.ENGINE}
                        onSelectTab={(newTabKey) => setTabKey(newTabKey)}
                        bordered={false}
                        disableTransition
                        tabsWrapperClassName="bg-white"
                        arrowClassName="left-10 right-10 width-auto bg-primary rotate-0 height-2 bottom-0"
                        tabClassName="bg-lightest"
                        tabHoverClassName="bg-white"
                        tabActiveClassName="bg-white padding-y-20"
                        tabContentClassName="rounded-bottom bg-white"
                    >
                        <DataTab tabKey={TABS.ENGINE} title={<DataTabTitle />}>
                            <div className={'equal-height row'}>
                                <EngineOilChangeOverdueStatusPanel
                                    dataTestId="engine-oil-change-status-panel"
                                    status={data.max_engine_oil_change_status.status}
                                    value={data.max_engine_oil_change_status.percentage!!}
                                    unit={PERCENTAGE_UNIT}
                                    titleI18n="intl-msg:maintenanceHistory.lifetime.maximumEngineOilChangeOverdue"
                                    tooltip={engineOilChangeTooltip(data)}
                                />

                                <SingleStatusPanel
                                    dataTestId="engine-oil-panel"
                                    status={data.max_engine_oil_status.status}
                                    value={data.max_engine_oil_status.temperature!!}
                                    percentageTimeAbove={data.max_engine_oil_status.percentage_total_time_above}
                                    unit={TEMPERATURE_UNIT}
                                    titleI18n="intl-msg:maintenanceHistory.lifetime.maximumEngineOilTemperature"
                                    tooltip={engineOilTemperatureTooltip(data)}
                                />

                                <SingleStatusPanel
                                    dataTestId="coolant-panel"
                                    status={data.max_coolant_status.status}
                                    value={data.max_coolant_status.temperature!!}
                                    percentageTimeAbove={data.max_coolant_status.percentage_total_time_above}
                                    unit={TEMPERATURE_UNIT}
                                    titleI18n="intl-msg:maintenanceHistory.lifetime.maximumCoolantTemperature"
                                    tooltip={coolantTemperatureTooltip(data)}
                                    panelExtraClasses={'margin-top-10'}
                                />

                                <DoubleStatusPanel
                                    dataTestId="engine-speed-panel"
                                    status={data.max_engine_speed.status}
                                    value={data.max_engine_speed.rpm!!}
                                    unit={RPM_UNIT}
                                    titleI18n="intl-msg:maintenanceHistory.lifetime.maximumEngineSpeed"
                                    secondValue={data.max_engine_speed.max_engine_over_speed_time!!}
                                    secondUnit={SECONDS_UNIT}
                                    secondTitleI18n="intl-msg:maintenanceHistory.lifetime.engineOverspeedTime"
                                    panelExtraClasses={'margin-top-10'}
                                />
                            </div>
                            {data.recommendation && (
                                <div className={'margin-top-15'}>
                                    <Recommendation />
                                </div>
                            )}
                        </DataTab>
                        {/* The following DataTab elements were added to shrink the Engine one to the expected size */}
                        <DataTab tabKey={'unused-1'} title={''} className={'disabled-tab'}>
                            <div className={'equal-height row visibility-hidden'} />
                        </DataTab>
                        <DataTab tabKey={'unused-2'} title={''} className={'disabled-tab'}>
                            <div className={'equal-height row visibility-hidden'} />
                        </DataTab>
                        <DataTab tabKey={'unused-3'} title={''} className={'disabled-tab'}>
                            <div className={'equal-height row visibility-hidden'} />
                        </DataTab>
                    </DataTabs>
                </ExpanderPanel>
            </>
        );
    }
};

export default LifetimeStatus;
