import SimpleTooltip from '@rio-cloud/rio-uikit/SimpleTooltip';
import { FormattedMessage, FormattedDate } from 'react-intl';
import { MaxOverdueOrigin } from '../../../generated/GeneratedApiTypes';

interface MaxEngineOilOverdueTooltipProps {
    serviceDate: string | undefined;
    isCurrent: boolean | undefined;
    origin: MaxOverdueOrigin | undefined;
}

const MaxEngineOilOverdueTooltip = ({ serviceDate, isCurrent, origin }: MaxEngineOilOverdueTooltipProps) => (
    <>
        <SimpleTooltip
            data-testid={'engine-oil-change-status-tooltip'}
            content={
                <FormattedMessage
                    id={
                        isCurrent
                            ? 'intl-msg:maintenanceHistory.lifetime.maximumEngineOilChangeOverdueCurrent.tooltip'
                            : 'intl-msg:maintenanceHistory.lifetime.maximumEngineOilChangeOverduePast.tooltip'
                    }
                    values={{
                        date: <FormattedDate value={serviceDate} year="numeric" month="numeric" day="numeric" />,
                        origin: (
                            <FormattedMessage id={`intl-msg:maintenanceHistory.lifetime.${origin?.toLowerCase()}`} />
                        ),
                    }}
                />
            }
            width={isCurrent ? 300 : undefined}
            textAlignment={isCurrent ? 'left' : undefined}
            innerClassName={isCurrent ? 'padding-y-5' : undefined}
        >
            <span
                data-testid={'engine-oil-change-status-icon-tooltip'}
                className="rioglyph rioglyph-info-sign text-size-16 margin-left-5"
            />
        </SimpleTooltip>
    </>
);

export default MaxEngineOilOverdueTooltip;
