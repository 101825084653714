import SimpleTooltip from '@rio-cloud/rio-uikit/SimpleTooltip';
import { FormattedMessage } from 'react-intl';

const TEMPERATURE_UNIT = ' °C';

interface PercentageTimeAboveTooltipProps {
    threshold: number;
}

const PercentageTimeAboveTooltip = ({ threshold }: PercentageTimeAboveTooltipProps) => {
    const tooltipId = 'intl-msg:maintenanceHistory.lifetime.totalPercentageTimeAbove.tooltip';
    return (
        <SimpleTooltip
            data-testid={'percentage-time-above-tooltip'}
            content={<FormattedMessage id={tooltipId} values={{ temperature: threshold, unit: TEMPERATURE_UNIT }} />}
            width={300}
            textAlignment={'left'}
            innerClassName={'padding-y-5'}
            placement={'right'}
        >
            <span
                data-testid={'percentage-time-above-icon-tooltip'}
                className="rioglyph rioglyph-info-sign text-size-16 margin-left-5"
            />
        </SimpleTooltip>
    );
};

export default PercentageTimeAboveTooltip;
