import { FormattedMessage, FormattedNumber } from 'react-intl';
import { SingleStatusPanelProps } from './SingleStatusPanel';

const EngineOilChangeOverdueStatusPanel = ({
    dataTestId,
    status,
    value,
    unit,
    titleI18n,
    tooltip,
    panelExtraClasses,
}: SingleStatusPanelProps) => (
    <div className={`col-xs-12 col-sm-6 col-md-6 display-flex ${panelExtraClasses}`}>
        <div
            className={`panel panel-default margin-bottom-0 width-100pct ${status === 'DANGER' ? 'status-critical' : 'status-ok'}`}
            data-testid={dataTestId}
        >
            <div className="panel-body">
                <div className={'display-flex'}>
                    <span className={'text-size-14'}>
                        <FormattedMessage id={titleI18n} />
                    </span>
                </div>
                {value > 0 && (
                    <div>
                        <FormattedNumber value={value} minimumFractionDigits={0} maximumFractionDigits={2} />
                        {unit}
                        {tooltip}
                    </div>
                )}
                {value <= 0 && <FormattedMessage id="intl-msg:maintenanceHistory.component.notOverdue" />}
            </div>
        </div>
    </div>
);

export default EngineOilChangeOverdueStatusPanel;
