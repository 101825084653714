import { FormattedMessage } from 'react-intl';
import { Criticality } from '../../../generated/GeneratedApiTypes';
import NoData from '../NoData';

interface DoubleStatusPanelProps {
    dataTestId: string;
    status: Criticality;
    value: number;
    unit: string;
    titleI18n: string;
    secondValue: number;
    secondUnit: string;
    secondTitleI18n: string;
    panelExtraClasses?: string;
}

const getPanelStatus = (status: Criticality) =>
    ({
        DANGER: 'status-critical',
        OK: 'status-ok',
        UNDEFINED: '',
    })[status] || '';

const DoubleStatusPanel = ({
    dataTestId,
    status,
    value,
    unit,
    titleI18n,
    panelExtraClasses,
    secondValue,
    secondUnit,
    secondTitleI18n,
}: DoubleStatusPanelProps) => (
    <div className={`col-xs-12 col-sm-6 col-md-6 display-flex ${panelExtraClasses}`}>
        <div
            className={`panel panel-default margin-bottom-0 width-100pct ${getPanelStatus(status)}`}
            data-testid={dataTestId}
        >
            <div className="panel-body">
                <div className={'display-flex row'}>
                    <div className={'display-flex flex-column col-xs-12 col-sm-6 col-md-6'}>
                        <span className={'text-size-14'}>
                            <FormattedMessage id={titleI18n} />
                        </span>
                        {value !== undefined ? (
                            <div>
                                {value} {unit}
                            </div>
                        ) : (
                            <NoData />
                        )}
                    </div>

                    {status === 'DANGER' && (
                        <div className={'display-flex flex-column col-xs-12 col-sm-6 col-md-6'}>
                            <span className={'text-size-14'}>
                                <FormattedMessage id={secondTitleI18n} />
                            </span>

                            <div>
                                {secondValue} {secondUnit}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    </div>
);

export default DoubleStatusPanel;
