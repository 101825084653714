import { FormattedMessage } from 'react-intl';
import { gaPush, GoogleAnalyticsCustomEvents } from '../../../configuration/setup/googleAnalytics';

const registerClickRecommendationLinkEventGA = (eventName: GoogleAnalyticsCustomEvents) => {
    gaPush({
        event: eventName,
        parameters: {
            element_name: 'Recommendation',
            component_name: 'Link',
            trigger: 'click',
            element_state: undefined,
        },
        userProps: {},
    });
};

const Recommendation = () => {
    return (
        <div className={'panel panel-default border-color-danger'}>
            <div className={'bg-critical'}>
                <div className="panel-body">
                    <div className="text-color-black">
                        <h5 className="text-size-16 margin-top-0">
                            <span className={'rioglyph rioglyph-info-sign margin-right-5 text-color-danger'} />
                            <FormattedMessage id="intl-msg:maintenanceHistory.lifetime.recommendation" />
                        </h5>
                        <div className="margin-left-25">
                            <ul>
                                <li>
                                    <FormattedMessage
                                        id="intl-msg:maintenanceHistory.lifetime.recommendation.010"
                                        values={{
                                            link: (
                                                <>
                                                    <a
                                                        href={
                                                            'https://secured.man.eu/vehicleexplorerui/?target=SI&man_language=en&nav=false'
                                                        }
                                                        className={'text-decoration-underline'}
                                                        target="_blank"
                                                        rel="noreferrer"
                                                        onClick={() =>
                                                            registerClickRecommendationLinkEventGA(
                                                                'open_link_recommendation_1',
                                                            )
                                                        }
                                                    >
                                                        5458SM
                                                    </a>
                                                </>
                                            ),
                                        }}
                                    />
                                </li>
                                <li>
                                    <FormattedMessage
                                        id="intl-msg:maintenanceHistory.lifetime.recommendation.020"
                                        values={{
                                            link: (
                                                <>
                                                    <a
                                                        href={
                                                            'https://secured.man.eu/vehicleexplorerui/?target=SI&man_language=en&nav=false'
                                                        }
                                                        className={'text-decoration-underline'}
                                                        target="_blank"
                                                        rel="noreferrer"
                                                        onClick={() =>
                                                            registerClickRecommendationLinkEventGA(
                                                                'open_link_recommendation_2',
                                                            )
                                                        }
                                                    >
                                                        6883SM
                                                    </a>
                                                </>
                                            ),
                                        }}
                                    />
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Recommendation;
